import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import olx from '../assets/images/olx.png';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animate: true,
            animateSidebar: true
        };

    }

    componentDidUpdate(prevProps) {

    }

    render() {

        return (
            <div className={this.props.sidebar ? this.state.animate ? "sidebar animate-sidebar" : "sidebar" : 'sidebar sidebar-hide'} >
                <ul>


                    <li>
                        <Link to=''>
                            <i className="mdi mdi-home"></i>
                            <span>{this.props.translate('Home')}</span>
                        </Link>
                    </li>



                



                    <li>
                        <a onClick={() => { this.props.openSubMenu('products') }}>
                            <i className="mdi  mdi-package-variant"></i>
                            <span>{this.props.translate('Products')}</span>
                        </a>

                        {
                            this.props.subMenu === 'products' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/products/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Add product')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/products'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('List of products')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>




                    <li>

                        <a onClick={() => { this.props.openSubMenu('categories') }}>
                            <i className="mdi  mdi-folder"></i>
                            <span>{this.props.translate('Categories')}</span>
                        </a>

                        {
                            this.props.subMenu === 'categories' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/categories/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Add category')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/categories'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('List of categories')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }
                    </li>



                    <li>
                        <a onClick={() => { this.props.openSubMenu('gallery') }}>
                            <i className="mdi mdi-image-area"></i>
                            <span>{this.props.translate('Home slider')}</span>
                        </a>

                        {
                            this.props.subMenu === 'gallery' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/slides/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Add slide')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/slides'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('List of slides')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }


                    </li>

                    <li>
                        <Link to='/gallery/__'>
                            <i className="mdi mdi-file-image"></i>
                            <span>{this.props.translate('Gallery')}</span>
                        </Link>



                    </li>


<li>
                        <a onClick={() => { this.props.openSubMenu('news') }}>
                            <i className="mdi mdi-newspaper"></i>
                            <span>{this.props.translate('News')}</span>
                        </a>

                        {
                            this.props.subMenu === 'news' ?
                                <ul className={this.state.animate ? "sub-menu animate-submenu" : "sub-menu"}>
                                    <li>
                                        <Link to='/news/new'>
                                            <i className="mdi  mdi-plus"></i>
                                            <span>{this.props.translate('Add news')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to='/news'>
                                            <i className="mdi  mdi-view-list"></i>
                                            <span>{this.props.translate('List of news')}</span>
                                        </Link>
                                    </li>
                                </ul>

                                : null

                        }


                    </li>

                    {/*
                    <li>
                        <Link to='/scraper/import'>
                            <img src={olx} />
                            <span>{this.props.translate('Import')}</span>
                        </Link>
                    </li>
                    */}

                    <li>
                        <a onClick={() => this.props.setUserData(null)}>
                            <i className="mdi mdi-power"></i>
                            <span>{this.props.translate('LogOut')}</span>
                        </a>
                    </li>




                </ul>

            </div>
        )
    }

};

export default Sidebar;