export const langs = {  
        "en":{  
           "Home":"Home",
           "Products":"Products",
           "Categories":"Categories",
           "Gallery":"Gallery",
           "Pages":"Pages",
           "Settings":"Settings",
           "Contacts":"Contacts",
           "LogOut":"LogOut",
           "Drag images to gallery":"Drag images to gallery",
           "Upload images":"Upload images",
           "Save":"Save",
           "Subtitle":"Subtitle",
           "Title":"Title",
           "Dashboard":"Dashboard",
           "Website statistics":"Website statistics",
           "Active clients":"Active clients",
           "Website Visits":"Website Visits",
           "This Week":"This Week",
           "Top 3 products":"Top 3 products",
           "Recent Visits":"Recent Visits",
           "Add product":"Add product",
           "List of products":"List of products",
           "Add new car":"Add new car",
           "Fill fields to add car":"Fill fields to add car",
           "Short description":"Short description",
           "Alias":"Alias",
           "Category":"Category",
           "Marka":"Marka",
           "Price":"Price",
           "Description":"Description",
           "Status":"Status",
           "Add":"Add",
           "Add new product":"Add new product",
           "Edit car":"Edit car",
           "Change fields to edit car":"Change fields to edit car",
           "Edit":"Edit",
           "Model":"Model",
           "Are you sure you want to delete?":"Are you sure you want to delete?",
           "Add category":"Add category",
           "List of categories":"List of categories",
           "Add new category":"Add new category",
           "Name":"Name",
           "Action":"Action",
           "Edit category":"Edit category",
           "Change fields to edit catagory":"Change fields to edit catagory",
           "Parent category":"Parent category",
           "Fill fields to add catagory":"Fill fields to add catagory",
           "Parrent category":"Parrent category",
           "Attributes":"Attributes",
           "Add new attrubute":"Add new attrubute",
           "List of attributes":"List of attributes",
           "Type":"Type",
           "Add new attribute":"Add new attribute",
           "Fill fields to add attribute":"Fill fields to add attribute",
           "Required":"Required",
           "Hidden":"Hidden",
           "Edit attribute":"Edit attribute",
           "Change fields to edit attribute":"Change fields to edit attribute",
           "Site settings":"Site settings",
           "Page Title":"Page Title",
           "Address":"Address",
           "Mobile Phone1":"Mobile Phone1",
           "Mobile Phone2":"Mobile Phone2",
           "Telephone":"Telephone",
           "E-Mail":"E-Mail",
           "Location":"Location",
           "Meta Description":"Meta Description",
           "Meta Tags":"Meta Tags",
           "Add page":"Add page",
           "List of pages":"List of pages",
           "Add new page":"Add new page",
           "Fill fields to add page":"Fill fields to add page",
           "Content":"Content",
           "Edit page":"Edit page",
           "Change fields to edit page":"Change fields to edit page",
           "List of contacts":"List of contacts",
           "Email":"Email",
           "Phone":"Phone",
           "Subject":"Subject",
           "Message":"Message"
        },
        "de":{  
           "Home":"Zuhause",
           "Products":"Products",
           "Categories":"Kategorien",
           "Gallery":"Galerie",
           "Pages":"Seiten",
           "Settings":"Einstellungen",
           "Contacts":"Kontakte",
           "LogOut":"Ausloggen",
           "Drag images to gallery":"Ziehen Sie Bilder in die Galerie",
           "Upload images":"Bilder hochladen",
           "Save":"Sparen",
           "Subtitle":"Untertitel",
           "Title":"Titel",
           "Dashboard":"Instrumententafel",
           "Website statistics":"Website-Statistiken",
           "Active clients":"Aktive Kunden",
           "Website Visits":"Website-Besuche",
           "This Week":"Diese Woche",
           "Top 3 products":"Top 3 Produkte",
           "Recent Visits":"Letzte Besuche",
           "Add product":"Auto hinzufügen",
           "List of products":"Liste der products",
           "Add new car":"Neues Auto hinzufügen",
           "Fill fields to add car":"Füllen Sie die Felder aus, um ein Auto hinzuzufügen",
           "Short description":"Kurze Beschreibung",
           "Alias":"Alias",
           "Category":"Kategorie",
           "Marka":"Marke",
           "Price":"Preis",
           "Description":"Beschreibung",
           "Status":"Status",
           "Add":"Hinzufügen",
           "Add new product":"Neues Auto hinzufügen",
           "Edit car":"Auto bearbeiten",
           "Change fields to edit car":"Ändern Sie die Felder, um das Auto zu bearbeiten",
           "Edit":"Bearbeiten",
           "Model":"Model",
           "Are you sure you want to delete?":"Sind Sie sicher, dass Sie löschen möchten?",
           "Add category":"Kategorie hinzufügen",
           "List of categories":"Liste der Kategorien",
           "Add new category":"Neue Kategorie hinzufügen",
           "Name":"Name",
           "Action":"Aktion",
           "Edit category":"Kategorie bearbeiten",
           "Change fields to edit catagory":"Ändern Sie die Felder, um die Kategorie zu bearbeiten",
           "Parent category":"Eltern-Kategorie",
           "Fill fields to add catagory":"Füllen Sie die Felder aus, um die Kategorie hinzuzufügen",
           "Parrent category":"Eltern-Kategorie",
           "Attributes":"Attribute",
           "Add new attrubute":"Neues Attribut hinzufügen",
           "List of attributes":"Liste der Attribute",
           "Type":"Art",
           "Add new attribute":"Neues Attribut hinzufügen",
           "Fill fields to add attribute":"Füllen Sie die Felder aus, um das Attribut hinzuzufügen",
           "Required":"Erforderlich",
           "Hidden":"Versteckt",
           "Edit attribute":"Attribut bearbeiten",
           "Change fields to edit attribute":"Ändern Sie die Felder, um das Attribut zu bearbeiten",
           "Site settings":"Seiteneinstellungen",
           "Page Title":"Seitentitel",
           "Address":"Adresse",
           "Mobile Phone1":"Mobiltelefon1",
           "Mobile Phone2":"Mobiltelefon2",
           "Telephone":"Telefon",
           "E-Mail":"E-Mail",
           "Location":"Ort",
           "Meta Description":"Meta-Beschreibung",
           "Meta Tags":"Meta-Tags",
           "Add page":"Seite hinzufügen",
           "List of pages":"Liste der Seiten",
           "Add new page":"Neue Seite hinzufügen",
           "Fill fields to add page":"Füllen Sie die Felder aus, um die Seite hinzuzufügen",
           "Content":"Inhalt",
           "Edit page":"Seite bearbeiten",
           "Change fields to edit page":"Ändern Sie die Felder, um die Seite zu bearbeiten",
           "List of contacts":"Liste der Kontakte",
           "Email":"Email",
           "Phone":"Telefon",
           "Subject":"Gegenstand",
           "Message":"Botschaft"
        }
    
 };