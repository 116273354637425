import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import TextMultiline from './fields/textMultiline';
import FileMultiple from './fields/fileMultiple';
import File from './fields/file';
import Html from './fields/html';
import List from './fields/list';

import Select from './fields/select';
import Check from './fields/check';
import Link from './fields/link';
import Label from './fields/label';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate
}) => (

        <Check
            label={label}
            translate={translate}
            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    multilang,
    lang,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            multilang={multilang}
            lang={lang}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

    const renderLinkField = ({
        input,
        placeholder,
        label,
        type,
        meta: { touched, error },
        beforeHref,
        afterHref,
    }) => (
    
            <Link
                placeholder={placeholder}
                label={label}
                type={type}
                errorText={touched && error}
                error={touched && error}
                beforeHref={beforeHref}
                afterHref={afterHref}        
                {...input}
            />
        )


        const renderLabelField = ({
            input,
            placeholder,
            label,
            type,
            meta: { touched, error },
            afterText,
            beforeText
        }) => (
        
                <Label
                    placeholder={placeholder}
                    label={label}
                    type={type}
                    errorText={touched && error}
                    error={touched && error}
                    {...input}
                    afterText={afterText}
                    beforeText={beforeText}
                />
            )
    

const renderTextMultilineField = ({
    input,
    placeholder,
    label,
    type,
    multilang,
    lang,
    meta: { touched, error },
}) => (

        <TextMultiline
            placeholder={placeholder}
            label={label}
            type={type}
            multilang={multilang}
            lang={lang}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderFileMultipleField = ({
    input,
    placeholder,
    label,
    type,
    socketIOClient,
    meta: { touched, error },
}) => (

        <FileMultiple
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}
            socketIOClient={socketIOClient}


            {...input}
        />
    )
const renderFileField = ({
    input,
    placeholder,
    label,
    type,
    socketIOClient,
    meta: { touched, error },
}) => (

        <File
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}
            socketIOClient={socketIOClient}

            {...input}
        />
    )

const renderHtmlField = ({
    input,
    placeholder,
    label,
    type,
    multilang,
    lang,
    meta: { touched, error },
}) => (

        <Html
            placeholder={placeholder}
            label={label}
            type={type}
            multilang={multilang}
            lang={lang}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )

    const renderListField = ({
        input,
        placeholder,
        label,
        meta: { touched, error },
        children,
        additionalAction,
        scope,
        fields,
        disableDelete
    }) => (
    
            <List
                placeholder={placeholder}
                label={label}
                errorText={touched && error}
                error={touched && error}
                additionalAction={additionalAction}
                scope={scope}
                fields={fields}
                {...input}
                children={children}
                disableDelete={disableDelete}
            />
        )

const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
            <Container fluid>
                <Row>

                    {
                        props.fields.map((item, idx) => {
                            let component;

                            if (item.type === "text") {
                                component = renderTextField;
                            } else if (item.type === "text-multiline") {
                                component = renderTextMultilineField;
                            } else if (item.type === "html") {
                                component = renderHtmlField;
                            } else if (item.type === "select") {
                                component = renderSelectField;
                            } else if (item.type === "checkbox") {
                                component = renderCheckField;
                            } else if (item.type === "file") {
                                component = renderFileField;
                            } else if (item.type === "file-multiple") {
                                component = renderFileMultipleField;
                            }else if (item.type === "list") {
                                component = renderListField;
                            }else if (item.type === "link"){
                                component = renderLinkField;
                            }else if (item.type === "label"){
                                component = renderLabelField;
                            }

                            if (item.type == "spacer")
                                return (
                                    <Col lg={12}>

                                        <div className="spacer"></div>
                                    </Col>
                                )
                            if (item.type == "offset")
                                return (
                                    <Col lg={item.width.desktop} xs={item.width.mobile}>

                                    </Col>
                                )
                            if (item.type == "title")
                                return (
                                    <Col lg={12} xs={12}>

                                        <h3>{item.text}</h3>

                                    </Col>
                                )

                            if (item.type == "button") {
                                return (
                                    <Col lg={item.width.desktop} xs={item.width.mobile}>
                                        <button button type="button" className="button" onClick={() => item.onClick(props.scope)}>{item.text}</button>
                                    </Col>
                                )
                            }

                    

                            if (item.type == "search") {
                                return (
                                    <Col lg={item.width.desktop} xs={item.width.mobile}>
                                        <div className="input-wrap search-wrap">
                                            <input onChange={(e) => { props.search(item.collection, item.searchFields, item.limit, item.id, e.target.value) }} placeholder={item.label} type="text"></input>
                                            <i className="mdi mdi-magnify" />


                                            {props.searchResults[item.id] ?

                                                <div className="search-results">
                                                    <Container>

                                                        {
                                                            props.searchResults[item.id].map((result, idx1) => {
                                                                return (
                                                                    <Row key={idx1} onClick={() => props.handleSearchClick(item.id, result, item.onSelect)}>
                                                                        {
                                                                            item.fields.map((field, idx2) => {
                                                                                let broken = field.name.split('.');
                                                                                let value = result;

                                                                                for (let i = 0; i < broken.length; i++) {
                                                                                    value = value[broken[i]];
                                                                                }

                                                                                if (field.type === 'image') {
                                                                                    return (<Col lg="1" xs="2"><img key={idx2} src={value} /></Col>)
                                                                                } else {
                                                                                    return (<Col lg="5" xs="5">{value}</Col>)
                                                                                }
                                                                            })
                                                                        }
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    </Container>

                                                </div>

                                                : null
                                            }
                                        </div>
                                    </Col>
                                )
                            }

                            return (
                                <Col lg={item.width.desktop} xs={item.width.mobile}>

                                    <Field
                                        name={item.name}
                                        component={component}
                                        label={props.translate(item.label)}
                                        placeholder=""
                                        multilang={item.multilang}
                                        lang={props.lang}
                                        additionalAction={item.additionalAction}
                                        validate={item.required ? required : null}
                                        socketIOClient={props.socketIOClient}
                                        scope={props.scope}
                                        fields={item.fields}
                                        disableDelete={item.disableDelete}
                                        afterText={item.afterText}
                                        beforeText={item.beforeText}
                                        beforeHref={item.beforeHref}
                                        afterHref={item.afterHref}                                
                                    >
                                        {
                                            item.values ? item.values.map((value, idx1) => {
                                                return (
                                                    <option key={idx1} value={value.value}>{value.name}</option>
                                                )
                                            })

                                                : null
                                        }
                                    </Field>


                                </Col>
                            )
                        })
                    }

                    <Col lg="12">
                        <button type="submit" className="button">{props.translate('SPEICHERN')}</button>
                    </Col>
                </Row>
            </Container>

        </form>
    )
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
