import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import LoginPage from './views/loginPage';
import ListPage from './views/listPage';
import FormPage from './views/formPage';

//const history = createHistory();


let formPages = [
    {
        title: 'Product',
        subtitle: 'Change product',
        link: '/products',
        collection: 'products',
        multilang: false,
        fields: [
            
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Name',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShortDescription',
                label: 'Short description',
                type: 'text-multiline',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'Description',
                label: 'Description',
                type: 'html',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
           
            {
                type: 'spacer',
            },
            {
                name: 'position',
                label: 'Position',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Is Visible',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'IsPopular',
                label: 'Is Popular',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'category',
                label: 'Category',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }

        ]

    },

 {
        title: 'Category',
        subtitle: 'Change category',
        link: '/categories',
        collection: 'categories',
        multilang: false,
        fields: [
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'name',
                label: 'Name',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'breadcrumb',
                label: 'Breadcrumb',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'position',
                label: 'Position',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Is Visible',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'parentCategory',
                label: 'Parent Category',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }

        ]

    },
   
    {
        title: 'Slide',
        subtitle: 'Change slide',
        link: '/slides',
        collection: 'slides',
        multilang: false,
        fields: [

            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ImageAnimated',
                label: 'ImageAnimated',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'title',
                label: 'Title',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'subtitle',
                label: 'Subtitle',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'description',
                label: 'Description',
                type: 'text-multiline',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'color',
                label: 'Color',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'link',
                label: 'Link',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'News',
        subtitle: 'Change news',
        link: '/news',
        collection: 'news',
        multilang: false,
        fields: [

            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'Subtitle',
                label: 'Subtitle',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShortDescription',
                label: 'Short Description',
                type: 'text-multiline',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Description',
                label: 'Description',
                type: 'html',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
           
            {
                type: 'spacer'
            },

            {
                name: 'IsVisible',
                label: 'Is Visible',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },


        ],
        linkedFields: []

    },
    {
        title: 'Gallery',
        subtitle: 'Change Gallery',
        link: '/gallery',
        collection: 'gallery',
        multilang: false,
        backUrl: '/',
        fields: [

            {
                name: 'Images',
                label: 'Images',
                type: 'file-multiple',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            
         
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    }
]


let listPages = [
    {
        title: 'Products',
        subtitle: 'List of products',
        link: '/products',
        haveImage: true,
        collection: 'products',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            }, {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Name',
                label: "Name",

                type: "text"
            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: 'Position',
                sort: -1
            }, {
                name: 'Name',
                field: 'Name',
                sort: 1
            },
        ]
    },
   {
        title: 'Categories',
        subtitle: 'List of categories',
        link: '/categories',
        haveImage: false,
        collection: 'categories',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: 'Alias',
                type: "text",
                label: "Alias"
            },
            {
                name: 'breadcrumb',
                type: "text",
                label: "Breadcrumb"

            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }, {
                name: 'Alias - ascending',
                field: 'Alias',
                sort: 1
            },
            {
                name: 'Alias - descending',
                field: 'Alias',
                sort: -1
            },

        ]
    },
    {
        title: 'Home slides',
        subtitle: 'List of slides',
        link: '/slides',
        haveImage: true,
        collection: 'slides',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            },{
                name: 'title',
                type: "text",
                label: 'Title'
            },
            {
                name: 'subtitle',
                type: "text",
                label: 'Subtitle'

            },
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'News',
        subtitle: 'List of news',
        link: '/news',
        haveImage: true,
        collection: 'news',
        searchFields: ['title'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            },{
                name: 'Title',
                type: "text",
                label: 'Title'

            },
           
        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
   
];


class Routes extends Component {

    componentDidMount() {
        /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */
    }

    render() {
        return (
            <Router >
                <div>
                    <GoogleMapScript API_KEY="AIzaSyByPzC2VWMbbvAyR3MgBZktkV3gc-nc3rY" />

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />

                        


                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />

                        {
                            listPages.map((page, idx) => {
                                return (
                                    <Route
                                        path={page.link}
                                        exact
                                        render={(...renderProps) => (
                                            <ListPage {...page} {...renderProps} {...this.props} />
                                        )}
                                    />
                                )

                            })

                        }

                        {
                            formPages.map((page, idx) => {
                                return (
                                    <Route
                                        path={`${page.link}/:id`}
                                        exact
                                        render={(...renderProps) => (
                                            <FormPage {...page} {...renderProps} {...this.props} />
                                        )}
                                    />
                                )

                            })
                        }


                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;