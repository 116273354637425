
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import Dropzone from 'react-dropzone';


class File extends Component {
    constructor(props) {
        super(props);
        this.dropzone = React.createRef()
        this.getBase64 = this.getBase64.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
        };
    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('adminUpload', (data) => {
            console.log(data);
            if (data.successful && data.name === this.state.filename){
                this.setState({
                    _uploading: null,
                    filename: null
                }, () => {
                    this.props.onChange(data.file.url);
                })
            }else if (!data.successful && data.name === this.state.filename){
                this.setState({
                    _uploading: null
                })
            }
        });

    }

    removeImage() {
        this.props.socketIOClient.emit("adminDeleteFile", {url: this.props.value});

        this.props.onChange(null);
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result;
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    }






    render() {



        return (
            <div className="input-wrap">
                <label>{this.props.label}</label>
                <div className="file-drop" ref={(ref) => this.dropzone = ref}>


                    <Dropzone
                        onDrop={async (imageFiles) => {

                            console.log(imageFiles[0]);
                            var base64Image = await this.getBase64(imageFiles[0]);
                            

                            //this.props.onChange(base64Image);
                            this.setState({
                                _uploading: true,
                                filename: imageFiles[0].name
                            }, () => {
                                this.props.socketIOClient.emit("adminUpload", {
                                    data: base64Image,
                                    name: imageFiles[0].name,
                                    type: imageFiles[0].type
                                });
                            });

                            

                        }}
                        className='dropzone'
                        activeClassName='active-dropzone'
                        multiple={false}>

                        <button type="button"><i className="mdi mdi-file-outline"></i></button>


                    </Dropzone>
                    
                    
                    {this.props.value ?
                        <div className="preview">

                            <img src={this.props.value} />
                            <div className='options'>
                                <i className="mdi mdi-delete" onClick={() => this.removeImage()}></i>
                            </div>

                        </div> : null

                    }

                    { this.state._uploading && 
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                    }
                </div>









            </div>
        );
    }
}

export default File;