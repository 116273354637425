import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import logo from '../assets/svg/cms.svg';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.login = this.login.bind(this);
        this.state = {
            pages: []
        };
    }


    componentDidMount() {

        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

       
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('login', (data) => {
            this.props.handleLoader(null);

            console.log(data);
            if (data.successful){
                this.props.setUserData(data.user);
            }
            this.setState({
                pages: data
            });
        });

    }

    login(){

        this.props.handleLoader(true);


        this.props.socketIOClient.emit("login", {
            username: this.state.username,
            password: this.state.password
        });


    }


    componentWillUnmount(){ if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("login");
    }

    render() {

        return (
            <div className="login-page">    

              {
                  this.props.uData && <Redirect to='/' />

              }
                <div className="modal">
                    <Isvg src={logo} />
                    <div className="input-wrap">
                        <input type="text" placeholder="Username" value={this.state.username} onChange={(e) => { this.setState({ username: e.target.value }) }}/>
                    </div>

                     <div className="input-wrap">
                        <input type="password" placeholder="Password" value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value }) }}/>
                    </div>

                    <div className="input-wrap">
                        <button className="button" onClick={this.login}>{this.props.translate('LogIn')}</button>
                    </div>


                </div>
            </div>
        );
    }
}

export default LoginPage;