import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
//import { Line, Polar } from 'react-chartjs-2';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      fill: true,
      lineTension: 0.45,
      backgroundColor: 'rgba(146,44,136,0.2)',
      borderColor: '#922c88',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(146,44,136,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(146,44,136,0.8)',
      pointHoverBorderWidth: 7,
      pointRadius: 6,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ],
};


class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {

    return (
      <div className="content">
        {

          (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

        }
          
        <div className="panel ">
          <div className="panel-title">
            <h1>{this.props.translate('Dashboard')}</h1>
            <h6>{this.props.translate('Website statistics')}</h6>

          </div>
        </div>

          </div>



    );
  }
}

export default PageWithLayout(HomePage);