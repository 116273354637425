
import React, { Component } from 'react';

import Isvg from 'react-inlinesvg';

class Link extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <div className="input-wrap">
                <a href={this.props.beforeHref+this.props.value+this.props.afterHref}>{this.props.label}</a>
            </div>
        );
    }
}

export default Link;