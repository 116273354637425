
import React, { Component } from 'react';

import Isvg from 'react-inlinesvg';

class Label extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <div className="input-wrap">
               <h3>{this.props.beforeText ? this.props.beforeText : null }{this.props.value}{this.props.afterText ? this.props.afterText : null }</h3>
            </div>
        );
    }
}

export default Label;